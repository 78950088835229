import {CustomSVGProps} from '../../types/CustomSVGProps.types';

export function IconCineamoSolid48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.43406 37.5611C9.22761 38.8219 10.8395 39.3407 12.2406 38.7676C12.2406 38.7676 16.69 36.994 17 36.8734C16.628 36.4511 16.0409 36.0228 15.9975 34.7016L16 6.03443C16 4.35735 17.1321 3.01207 18 3.01207L14.6522 3C13.5425 3 12.5196 3.58517 11.974 4.5323L1.40375 22.757C0.864388 23.692 0.864388 24.8382 1.40995 25.7673L8.43406 37.5611Z"
                fill="#00FFDC"/>
            <path
                d="M46.8761 27.3838L37.9291 43.4409C37.3911 44.4046 36.3936 45 35.3115 45H14.7093C13.6272 45 12.6297 44.4046 12.0917 43.4471L10 39.6906C10.3385 40.378 11.6866 40.5499 13.1194 39.8256L45.7457 26.0764C46.5437 25.7326 47.3054 26.6165 46.8761 27.3838Z"
                fill="#00FFDC"/>
            <path
                d="M46.877 20.6127L37.9918 4.55876C37.4575 3.59527 36.4669 3 35.3922 3H21C19.349 3 18 4.29396 18 6V11C18.3722 10.8343 18.7805 10.8429 19.2067 10.8429C19.5489 10.8429 19.8731 10.8981 20.1793 11.0086C20.5455 11.1375 45.7543 21.926 45.7543 21.926C46.5468 22.2636 47.3033 21.3799 46.877 20.6127Z"
                fill="#00FFDC"/>
        </svg>
    );
}

export function IconCineamoSolid24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.49838 19.2406C3.87182 19.906 4.63035 20.1798 5.28969 19.8774C5.28969 19.8774 7.3144 18.9413 7.46027 18.8776C7.28523 18.6547 7.07809 18.4287 7.05767 17.7314L7.03724 2.6015C7.03724 1.71638 7.59156 1.00637 8 1.00637L6.42458 1C5.90236 1 5.42098 1.30884 5.16424 1.80871L0.19 11.4273C-0.0638175 11.9208 -0.0638176 12.5257 0.192918 13.0161L3.49838 19.2406Z"
                fill="#00FFDC"/>
            <path
                d="M23.9331 14.6555L19.0968 22.2615C18.806 22.718 18.2668 23 17.6819 23H6.54555C5.96063 23 5.42146 22.718 5.13063 22.2644L4 20.485C4.18299 20.8107 4.91169 20.8921 5.68614 20.549L23.322 14.0362C23.7533 13.8734 24.1651 14.292 23.9331 14.6555Z"
                fill="#00FFDC"/>
            <path
                d="M23.9321 9.34288L19.0299 1.73836C18.7351 1.28197 18.1886 1 17.5957 1H9.64952C8.73864 1 8 1.65406 8 2.46218V4.83133C8.20536 4.75284 8.4306 4.71505 8.66577 4.71505C8.85457 4.71505 9.03344 4.74121 9.20236 4.79354C9.40441 4.85458 23.3127 9.96496 23.3127 9.96496C23.75 10.1248 24.1673 9.70625 23.9321 9.34288Z"
                fill="#00FFDC"/>
        </svg>
    );
}

export function IconCineamoSolid16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.4198 1H4.58043C4.22157 1 3.89023 1.19229 3.71218 1.50386L0.283612 7.50386C0.107936 7.81129 0.107937 8.18871 0.283612 8.49614L3.71218 14.4961C3.89023 14.8077 4.22157 15 4.58043 15H11.4198C11.7786 15 12.11 14.8077 12.288 14.4961L14.8814 9.95765C15.1222 9.53638 14.6821 9.05362 14.2404 9.2544L6.70701 12.6787C6.37596 12.8292 6.0001 12.5871 6.0001 12.2235V3.7765C6.0001 3.41286 6.37596 3.17084 6.70701 3.32132L14.2404 6.7456C14.6821 6.94638 15.1222 6.46362 14.8814 6.04235L12.288 1.50386C12.11 1.19229 11.7786 1 11.4198 1Z"
                  fill={props.fill || '#00FFDC'}/>
        </svg>
    );
}
