import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconCineamoOutline48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.6191 2L35.072 2C36.5064 2.01236 37.8446 2.75888 38.5734 3.98971L47.7344 19.459C48.2038 20.2515 48.0055 21.1251 47.505 21.6745C47.0085 22.2196 46.1773 22.4955 45.3493 22.1665L45.3469 22.1655L22.3962 12.9766L19.11 11.7029C17.6891 11.1524 16.2381 12.1805 16.2381 13.5318L14.2381 13.5318L14.2381 7.30768C14.2381 4.3605 16.6632 2 19.6191 2ZM16.2381 7.3024C16.241 5.50385 17.7309 4.0182 19.6236 4L35.0587 4C35.8145 4.00785 36.4931 4.40171 36.8525 5.00872L45.8583 20.2159L23.1345 11.1179L19.8327 9.83805C18.5933 9.35781 17.2803 9.51423 16.2381 10.1132L16.2381 7.30769L16.2381 7.3024Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.4224 2L19.6191 2L19.6276 3.99996C17.7311 4.01612 16.2381 5.50549 16.2381 7.30768L16.2381 34.2308C16.2381 35.9796 17.8158 37.2486 19.2864 36.8398C19.2964 36.837 19.3058 36.8343 19.3147 36.8318C19.3212 36.8299 19.3274 36.8281 19.3333 36.8263L19.9726 38.72L13.2942 41.2444L13.2851 41.2476C10.6546 42.1846 8.70028 40.3468 7.8257 38.9875L7.81199 38.9662L0.532915 26.2842L0.531798 26.2823C-0.177253 25.0405 -0.17729 23.516 0.53183 22.2742L10.9445 4.03553C11.6571 2.78768 12.9783 2.01287 14.4135 2.00004L14.4224 2ZM14.4273 4C13.7056 4.00787 13.0406 4.39806 12.6813 5.02713C12.6813 5.02717 12.6814 5.02709 12.6813 5.02713L2.26865 23.2659C1.9107 23.8927 1.91045 24.6626 2.26815 25.2897C2.2683 25.29 2.26799 25.2895 2.26815 25.2897L9.52078 37.9257C10.2385 39.0298 11.3482 39.8093 12.6057 39.3665L16.1226 38.0372C14.978 37.158 14.2381 35.7298 14.2381 34.2308L14.2381 7.30768C14.2381 6.04811 14.6805 4.90214 15.4159 4L14.4273 4Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M45.7886 28.8876L37.0311 43.0633C36.687 43.6204 36.0178 44 35.2531 44H14.5449C13.78 44 13.1109 43.6205 12.7668 43.0633L11.802 41.5018C12.269 41.5093 12.7647 41.433 13.2851 41.2476L13.2959 41.2438L18.1691 39.3812L45.7886 28.8876ZM9.50766 37.9053L7.81591 38.972C7.77991 38.9179 7.84851 39.0247 7.81591 38.972L9.63467 41.799L11.0652 44.1144C11.7992 45.3026 13.1365 46 14.5449 46H35.2531C36.6615 46 37.9987 45.3024 38.7326 44.1145L47.7272 29.555C48.2125 28.7696 48.0011 27.9016 47.4949 27.369C46.9983 26.8463 46.1875 26.5962 45.3868 26.9008L17.4569 37.5123L12.6042 39.3671C11.3916 39.7929 10.1837 38.9101 9.50766 37.9053Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconCineamoOutline24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.41941 1L17.2998 1C18.1603 1.00738 18.9721 1.45327 19.4178 2.20263M19.4178 2.20263L23.4557 8.98864C23.814 9.59074 23.6564 10.2578 23.2817 10.6672C22.9154 11.0675 22.3027 11.2738 21.6944 11.0426L11.0601 7.24523L9.47416 6.63319C9.10506 6.49086 8.81095 6.77177 8.80953 7.01341L8.80952 7.01516V16.8718C8.80952 17.1085 8.92064 17.3339 9.09084 17.4791C9.23333 17.6007 9.38944 17.6466 9.54417 17.6227L21.6991 13.065L21.7014 13.0641C22.291 12.8415 22.8959 13.0246 23.2688 13.4154C23.6493 13.814 23.8189 14.4804 23.4545 15.0819L19.4957 21.8443L19.4889 21.8553C19.0353 22.5863 18.2214 23 17.3819 23H7.47796C6.63854 23 5.82454 22.5864 5.37094 21.8552L4.68932 20.7568L3.85778 19.47C3.84379 19.449 3.83016 19.4278 3.81691 19.4064L3.80838 19.3927L0.325046 13.3501L0.323923 13.3481C-0.107941 12.5951 -0.107998 11.67 0.323895 10.9169L5.30388 2.23178C5.73767 1.47548 6.54052 1.00778 7.41052 1.00004L7.41941 1M5.52034 18.3578L2.05888 12.3532C1.98076 12.2164 1.98053 12.0484 2.05885 11.9119L6.8497 3.55659C6.82329 3.71712 6.80953 3.88231 6.80953 4.05128L6.80952 7.01002V7.01516V16.8718C6.80952 17.4405 6.9958 18.0004 7.31628 18.4658L6.39371 18.8169C6.35276 18.8251 6.21226 18.8314 5.98458 18.7241C5.75688 18.6168 5.59419 18.4649 5.52728 18.3686L5.52034 18.3578ZM7.01865 20.7174C7.02169 20.7164 7.02473 20.7154 7.02776 20.7144L7.0494 20.7073L9.39252 19.8156L9.39709 19.8139L10.1244 19.5411C10.1298 19.5397 10.1351 19.5382 10.1405 19.5367C10.1568 19.5322 10.1731 19.5276 10.1893 19.5228L10.1877 19.5174L20.8896 15.5045L17.785 20.8078C17.7206 20.9054 17.5771 21 17.3819 21H7.47796C7.27805 21 7.13246 20.9008 7.07045 20.8009L7.01865 20.7174ZM8.80953 4.64482C9.25799 4.55836 9.73658 4.59075 10.1941 4.76725L11.7565 5.37023L20.923 8.64351L17.6992 3.22556C17.6245 3.10006 17.472 3.00268 17.2858 3H9.90963C9.27389 3.00723 8.81134 3.49776 8.80954 4.04804L8.80953 4.05128L8.80953 4.64482Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconCineamoOutline16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.4197 1H4.58034C4.22148 1 3.89014 1.19229 3.71209 1.50386L0.283521 7.50386C0.107845 7.81129 0.107845 8.18871 0.283521 8.49614L3.71209 14.4961C3.89014 14.8077 4.22148 15 4.58034 15H11.4197C11.7785 15 12.1099 14.8077 12.2879 14.4961L14.8814 9.95765C15.1221 9.53638 14.682 9.05362 14.2403 9.2544L6.70691 12.6787C6.37586 12.8292 6.00001 12.5871 6.00001 12.2235V3.7765C6.00001 3.41286 6.37586 3.17084 6.70691 3.32132L14.2403 6.7456C14.682 6.94638 15.1221 6.46362 14.8814 6.04235L12.2879 1.50386C12.1099 1.19229 11.7785 1 11.4197 1Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
